// exports.api = "http://localhost:8888/";  // Developer PC
// exports.base_url = "http://localhost:8888/";  // Developer PC

// exports.api = "https://thesisapi.martersolutions.com/";  // Marter Server
// exports.base_url = "https://thesisapi.martersolutions.com/";  // Marter Server

exports.api = "https://uvslibrary.edu.mm:8888/";  // UVS Server
exports.base_url = "https://uvslibrary.edu.mm/api/";  // UVS Server


// exports.api = "http://202.165.95.36:8888/"; // DAR Server
// exports.base_url = "http://202.165.95.36/"; // DAR Server

// exports.user_info = JSON.parse(localStorage.getItem('user_info'));

var moment = require("moment-timezone");
// to get date with format('your format')

var c_date = moment()
  .tz("Asia/Yangon")
  .format();

var date = new Date(c_date),
  y = date.getFullYear(),
  m = date.getMonth(),
  d = date.getDate();

var backDate = new Date(y, m, d - 5);
var endDate = new Date(y, m + 1, 0);

exports.getCurrentDate = () => {
  var m = new Date();
  var dateString =
  m.getUTCFullYear() + "-" +
  ("0" + (m.getUTCMonth()+1)).slice(-2) + "-" +
  ("0" + m.getUTCDate()).slice(-2) + " " +
  ("0" + m.getUTCHours()).slice(-2) + ":" +
  ("0" + m.getUTCMinutes()).slice(-2) + ":" +
  ("0" + m.getUTCSeconds()).slice(-2);
  return dateString;
}

exports.backDate = backDate;

exports.endDate = endDate;
