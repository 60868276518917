import React, { Component } from "react";
import { api } from "../../config/config";
import { Link } from "react-router-dom";

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
           
        };

        // This binding is necessary to make `this` work in the callback    
        // this.VerifyLogin = this.VerifyLogin.bind(this);
        // this.handleUserEmailChange = this.handleUserEmailChange.bind(this);
        // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    render() {
        return (
            <div>
                {/* <!-- Start: Header Section --> */}
                    <header id="header" className="navbar-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <nav className="navbar navbar-default">
                                    <div className="menu-wrap">
                                        <div className="navbar-header">
                                            <div className="navbar-brand">
                                                <h1>
                                                    <Link to={`/`}><img src="images/uvs_e_library_logo.png" alt="UVS e-Library" /></Link>
                                                </h1>
                                            </div>
                                        </div>
                                        {/* <!-- Navigation --> */}
                                        <div className="navbar-collapse hidden-sm hidden-xs">
                                            <ul className="nav navbar-nav">
                                                <li className="active">
                                                    <Link to={`/`}>Home</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/booksearch`}>Books &amp; Media</Link>
                                                </li>
                                                <li className="dropdown">
                                                    <a data-toggle="dropdown" className="dropdown-toggle disabled" href="news-events-list-view.html">News &amp; Events</a>
                                                    <ul className="dropdown-menu">
                                                        <li><a href="news-events-list-view.html">News &amp; Events List View</a></li>
                                                        <li><a href="news-events-detail.html">News &amp; Events Detail</a></li>
                                                    </ul>
                                                </li>
                                                <li><a href="services.html">Services</a></li>
                                                <li><a href="contact.html">Contact</a></li>
                                            </ul>
                                            {/* <!-- Header Topbar --> */}
                                            <div className="header-topbar hidden-md">
                                                <div className="topbar-links">
                                                    <a href="https://uvslibrary.edu.mm/admin"><i className="fa fa-lock"></i>Login</a>
                                                </div>
                                            </div>
                                            {/* <!-- Header Topbar --> */}
                                        </div>
                                        <div className="header-socialbar hidden-sm hidden-xs">
                                            <ul className="social-links">
                                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i className="fa fa-rss"></i></a></li>
                                            </ul>
                                            <ul className="share-links">
                                                <li className="dropdown">
                                                    <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                                                        <i className="fa fa-share"></i>
                                                    </a>
                                                    <div className="dropdown-menu social-dropdown">
                                                        <ul>
                                                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-rss"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mobile-menu hidden-md hidden-lg">
                                    <a href="#mobile-menu"><i className="fa fa-navicon"></i></a>
                                        <div id="mobile-menu">
                                            <ul>
                                                <li className="mobile-title">
                                                    <h4>Navigation</h4>
                                                    <a href="#" className="close"></a>
                                                </li>
                                                <li>
                                                    <a href="index-2.html">Home</a>
                                                </li>
                                                <li>
                                                    <a href="books-media-list-view.html">Books &amp; Media</a>
                                                </li>
                                                <li>
                                                    <a href="news-events-list-view.html">News &amp; Events</a>
                                                </li>
                                                <li><a href="contact.html">Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </header>
                    {/* <!-- End: Header Section --> */}

                    {/* <!-- Start: Slider Section --> */}
                    <div data-ride="carousel" className="carousel slide" id="home-v1-header-carousel">
                        {/* <!-- Carousel slides --> */}
                        <div className="carousel-inner">
                            <div className="item active">
                                <figure>
                                    <img alt="Home Slide" src="images/header-slider/home-v3/header-slide.jpg" />
                                </figure>
                                <div className="container">
                                    <div className="carousel-caption">
                                        <h2>Learn Anytime, Anywhere!</h2>
                                        <p>UVS e-Library gives you access to <strong>Books and e-Books.</strong></p>
                                        <div className="filter-box">
                                            <form action="http://libraria.demo.presstigers.com/index.html" className="banner-filter-box" method="get">
                                                <div className="form-group">
                                                    <label className="sr-only" for="keywords">Search by Keyword</label>
                                                    <input className="form-control" placeholder="Search by Keyword" id="keywords" name="keywords" type="text" />
                                                </div>
                                                <div className="form-group">
                                                    <select name="author" id="author" className="form-control">
                                                        <option>All Authors</option>
                                                        <option>Author 01</option>
                                                        <option>Author 02</option>
                                                        <option>Author 03</option>
                                                        <option>Author 04</option>
                                                        <option>Author 05</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <select name="category" id="category" className="form-control">
                                                        <option>All Categories</option>
                                                        <option>Category 01</option>
                                                        <option>Category 02</option>
                                                        <option>Category 03</option>
                                                        <option>Category 04</option>
                                                        <option>Category 05</option>
                                                    </select>
                                                </div>
                                                <div className="clearfix"></div>
                                                <input className="form-control" type="submit" value="Search" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End: Slider Section --> */}

                    {/* <!-- Start: Welcome Section --> */}
                    <section className="welcome-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="welcome-wrap">
                                        <div className="welcome-text">
                                            <h2 className="section-title">Welcome to the UVS e-Library</h2>
                                            <span className="underline left"></span>
                                            <p>UVS e-Library is for active learners (students, staffs, tutors and professors) who wants to have the whole library on their finger tips. It makes learning more easy by utilizing the technology. Varieties of books and ebooks are available on UVS e-Library portal and you can browse through easily with different filtering carieteria such as keywords, authors, language and categories.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="welcome-image"></div>
                    </section>
                    {/* <!-- End: Welcome Section --> */}

                    {/* <!-- Start: Footer --> */}
                    <footer className="site-footer">
                        <div className="container">
                            <div id="footer-widgets">
                                <div className="row">
                                    <div className="col-sm widget-container text-center">
                                        <div id="text-2" className="widget widget_text">
                                            <h3 className="footer-widget-title">About UVS e-Library</h3>
                                            <span className="underline left"></span>
                                            <div className="textwidget">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking.
                                            </div>
                                            <address>
                                                <div className="info">
                                                    <i className="fa fa-location-arrow"></i>
                                                    <span>University of Veterinary Science, Yezin, Zayar Thiri Township, Naypyidaw, Myanmar.</span>
                                                </div>
                                                <div className="info">
                                                    <i className="fa fa-envelope"></i>
                                                    <span><a href="mailto:contact@uvsyezin.edu.mm">contact@uvsyezin.edu.mm</a></span>
                                                </div>
                                            </address>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>                
                        </div>
                    </footer>
                    {/* <!-- End: Footer --> */}
            </div>
        );
    }
}